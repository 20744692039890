import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { client } from './client';
import { map } from 'rxjs/operators';
import { Persoon } from '../contracts/persoon';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  constructor() {}

  getData(): Observable<Array<Persoon>> {
    return from(client.request(`{
        allPersoons {
          id
          gsm
          naam
          postcode
          updatedAt
          voornaam
          woonplaats
          adreslijn1
          adreslijn2
          eMail
          groepen {
            id
            naam
          }
        }
      }
      `)).pipe(
          map((resp) => resp.allPersoons)
      );
  }
}
