export const OmschrijvingQuery = `omschrijving {
    ... on TekstRecord {
      id
      createdAt
      tekst
      updatedAt
    }
    ... on VideoRecord {
      id
      createdAt
      updatedAt
      video {
        height
        provider
        providerUid
        thumbnailUrl
        title
        url
        width
      }
    }
    ... on AfbeeldingenRecord {
      id
      createdAt
      updatedAt
      afbeeldingen {
        alt
        author
        basename
        blurUpThumb(size: 10, quality: 80, punch: 1.5)
        copyright
        filename
        height
        id
        notes
        mimeType
        size
        smartTags
        tags
        title
        url
        width
        responsiveImage(sizes: "(max-width: 600px) 100vw, 600px") {
          alt
          aspectRatio
          base64
          bgColor
          height
          sizes
          src
          srcSet
          title
          webpSrcSet
          width
        }
      }
    }
  }`;
