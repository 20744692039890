import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { client } from './client';
import { map } from 'rxjs/operators';
import { Materiaal } from '../contracts/materiaal';
import { OmschrijvingQuery } from './queries';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  constructor() {}

  getData() {
    return from(client.request(`{
        allMateriaals {
          id
          naam
        }
      }`)).pipe(
          map((resp) => resp.allMateriaals)
      );
  }

  getMaterial(id): Observable<Materiaal> {
    return from(
      client.request(`{
        materiaal(filter: {id: {eq: "${id}"}}) {
          id
          createdAt
          updatedAt
          naam
          ${ OmschrijvingQuery }
          leverancier {
            id
            naam
            opmerkingen
            contactpersonen {
              id
              naam
            }
          }
          opslagplaats {
            id
            naam
            adreslijn1
            adreslijn2
            postcode
            woonplaats
          }
          bijlages {
            alt
            author
            basename
            copyright
            filename
            format
            height
            mimeType
            notes
            size
            smartTags
            tags
            title
            url
            video {
              duration
              framerate
              mp4Url
              muxAssetId
              muxPlaybackId
              streamingUrl
              thumbnailUrl
            }
            width
          }
          contactpersonen {
            id
            naam
            postcode
            voornaam
            woonplaats
            adreslijn1
            adreslijn2
            eMail
            gsm
          }
        }
      }
      `)
    ).pipe(
      map(ev => ev.materiaal)
    );
  }
}
