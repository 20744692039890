import { Component, OnInit, Input } from '@angular/core';
import { Omschrijving } from 'src/app/contracts/omschrijving';

@Component({
  selector: 'app-omschrijving',
  templateUrl: './omschrijving.component.html',
  styleUrls: ['./omschrijving.component.scss']
})
export class OmschrijvingComponent implements OnInit {

  @Input() omschrijvingen: Array<Omschrijving>;

  constructor() { }

  ngOnInit() {

  }

}
