
import { GraphQLClient } from 'graphql-request';

const apiToken = '86aa6391709b027f4bffac58239f8f';
const endpoint = `https://graphql.datocms.com`;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + apiToken
};

export const client = new GraphQLClient(endpoint, { headers });
