import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/data/events.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Gebeurtenis } from 'src/app/contracts/gebeurtenis';
import { Persoon } from 'src/app/contracts/persoon';
import { Groep } from 'src/app/contracts/groep';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  event$: Observable<Gebeurtenis>;
  nextAndPrevious$: Observable<{
    next: { eventId: string; momentId: string };
    previous: { eventId: string; momentId: string };
  }>;

  constructor(
    private eventService: EventsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.event$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.eventService.getEvent(params.get('id'));
      })
    );


    this.nextAndPrevious$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.eventService.getNextAndPrevious(params.get('id'), params.get('moment'));
      })
    );
  }

  public displayLabel(item: Persoon|Groep): string {
    return  (item.hasOwnProperty('voornaam') ? (item as Persoon).voornaam + ' ' : '' ) + item.naam;
  }

}
