import { Component, OnInit, ViewChild } from '@angular/core';

import { Moment } from 'src/app/contracts/moment';
import { FilterableItem, FilterTextInputComponent } from 'src/app/ui/filter-text-input/filter-text-input.component';
import { EventsService } from 'src/app/data/events.service';
import { Gebeurtenis } from 'src/app/contracts/gebeurtenis';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, FilterableItem<Moment[], Moment> {
  moment$ = this.eventService.getTimeline();

  @ViewChild(FilterTextInputComponent, { static: true })
  filterTextInput: FilterTextInputComponent<Moment[], Moment>;

  constructor(private eventService: EventsService) {

  }

  ngOnInit() {
    this.filterTextInput.setFilterableItem(this);
  }

  filterFn(source: Moment[], filterText: string): Moment[] {
    if (filterText === '') { return source; }
    const regex = new RegExp(filterText, 'gi');
    return (source || []).reduce((prev, curr, idx, acc) => {
      if (curr.naam.match(regex)) {
        return [...prev, {...curr}];
      } else {
        const evts = (curr.events || []).filter((ev: Gebeurtenis) => ev.titel.match(regex));
        if (evts.length) {
          return [...prev, {...curr, events: evts}];
        }
      }
      return prev;
    }, []);
  }

}
