import { Component, OnInit } from '@angular/core';
import { MaterialsService } from 'src/app/data/materials.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {
  materials$ = this.materialService.getData();

  constructor(private materialService: MaterialsService) { }

  ngOnInit() {
  }

}
