import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { PersonsService } from 'src/app/data/persons';
import { Persoon } from 'src/app/contracts/persoon';
import {
  FilterTextInputComponent,
  FilterableItem,
} from 'src/app/ui/filter-text-input/filter-text-input.component';
import { startWith } from 'rxjs/operators';
import {
  FILTER_SERVICE_TOKEN,
  FilterServiceInterface,
} from 'src/app/utils/filter.service.interface';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent
  implements OnInit, FilterableItem<Persoon[], Persoon> {
  persons$ = this.personsService.getData().pipe(startWith([]));

  @ViewChild(FilterTextInputComponent, { static: true })
  filterTextInput: FilterTextInputComponent<Persoon[], Persoon>;

  constructor(
    private personsService: PersonsService,
    @Inject(FILTER_SERVICE_TOKEN) private filterService: FilterServiceInterface
  ) {}

  ngOnInit() {
    this.filterTextInput.setFilterableItem(this);
  }

  getGroepen(person: Persoon): string {
    return person.groepen.map((e) => e.naam).join(', ');
  }

  filterFn(source: Persoon[], filterText: string): Persoon[] {
    return this.filterService.filter(
      source,
      {
        naam: filterText,
        voornaam: filterText,
        adreslijn1: filterText,
        adreslijn2: filterText,
        postcode: filterText,
        woonplaats: filterText,
        eMail: filterText
      },
      true
    );
  }
}
