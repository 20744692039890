import { Component, OnInit } from '@angular/core';
import { MaterialsService } from 'src/app/data/materials.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Materiaal } from 'src/app/contracts/materiaal';
import { switchMap } from 'rxjs/operators';
import { FileField } from 'src/app/contracts/filefield';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {

  material$: Observable<Materiaal>;

  constructor(
    private materialService: MaterialsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.material$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
      this.materialService.getMaterial (params.get('id')))
    );
  }

  public getPictures(files: Array<FileField>): Array<FileField> {
    return files.filter(file => {
      return file.mimeType.toLowerCase().indexOf('image') === 0;
    });
  }

}
