import { Component, OnInit, Input } from '@angular/core';
import { Locatie } from 'src/app/contracts/locatie';

@Component({
  selector: 'app-locatie',
  templateUrl: './locatie.component.html',
  styleUrls: ['./locatie.component.scss']
})
export class LocatieComponent implements OnInit {

  constructor() { }

  @Input() locatie: Locatie;

  ngOnInit() {
  }

}
