import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EventsService } from './data/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'draaiboek';

  ngOnInit() {
  }

  constructor() {}
}
