import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './events/events.component';
import { MaterialsComponent } from './materials/materials.component';
import { SearchComponent } from './search/search.component';
import { ContactsComponent } from './contacts/contacts.component';
import { MenuComponent } from './menu/menu.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui/ui.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MaterialDetailComponent } from './materials/material-detail/material-detail.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { FILTER_SERVICE_TOKEN } from '../utils/filter.service.interface';
import { FilterService } from '../utils/filter.service';

@NgModule({
  providers: [
    { provide: FILTER_SERVICE_TOKEN, useClass: FilterService }
  ],
  declarations: [
    EventsComponent,
    MaterialsComponent,
    SearchComponent,
    ContactsComponent,
    MenuComponent,
    EventDetailComponent,
    MaterialDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatChipsModule,
    MatTreeModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule
  ],
})
export class PagesModule {}
