import { Component, OnInit, Input } from '@angular/core';
import { FileField } from 'src/app/contracts/filefield';

@Component({
  selector: 'app-bijlages',
  templateUrl: './bijlages.component.html',
  styleUrls: ['./bijlages.component.scss']
})
export class BijlagesComponent implements OnInit {

  @Input() bijlages: Array<FileField>;

  constructor() { }

  ngOnInit() {
  }

  public getLabel(filefield: FileField): string {
    return filefield.title ||
      filefield.alt ||
      filefield.filename ||
      filefield.url;
  }

}
