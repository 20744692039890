import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OmschrijvingComponent } from '../ui/omschrijving/omschrijving.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BijlagesComponent } from './bijlages/bijlages.component';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { LocatieComponent } from './locatie/locatie.component';
import { FilterTextInputComponent } from './filter-text-input/filter-text-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  exports: [
    OmschrijvingComponent,
    BijlagesComponent,
    LocatieComponent,
    FilterTextInputComponent,
  ],
  declarations: [
    OmschrijvingComponent,
    BijlagesComponent,
    LocatieComponent,
    FilterTextInputComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
})
export class UiModule {}
