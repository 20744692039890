import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { client } from './client';
import { map, tap } from 'rxjs/operators';
import { Gebeurtenis } from '../contracts/gebeurtenis';
import { OmschrijvingQuery } from './queries';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  cache;

  constructor() {
    this.cache = {
      timeline: null,
    };
  }

  getData() {
    return from(client.request(`{ allEvents { titel } }`));
  }

  getEvent(id): Observable<Gebeurtenis> {
    return from(
      client.request(`{
      event(filter: {id: {eq: "${id}"}}) {
        id
        voorbereidingTekst
        betrokkenen {
          ... on PersoonRecord {
            id
            naam
            voornaam
          }
          ... on GroepRecord {
            id
            naam
          }
        }
        bijlages {
          title
          url
        }
        voorbereidingLinks {
          id
          titel
        }
        ${OmschrijvingQuery}
        titel
        moment {
          id
          naam
        }
        locaties {
          id
        }
        verantwoordelijken {
          id
          naam
          voornaam
        }
      }
    }
    `)
    ).pipe(map((ev) => ev.event));
  }

  getTimeline() {
    const self = this;
    if (this.cache.timeline !== null) {
      return of(this.cache.timeline);
    }
    const query = `
    {
      allMoments(first:100, orderBy:start_ASC) {
        id
        naam
        start
      },
      allEvents {
        id
        titel
        moment {
          id
          naam
        }
      }
    }
    `;
    return from(client.request(query)).pipe(
      map((data) => {
        self.cache.timeline = data.allMoments.map((moment) => {
          return {
            ...moment,
            events: data.allEvents.filter((event) =>
              event.moment.some((m) => m.id === moment.id)
            ),
          };
        });
        return self.cache.timeline;
      })
    );
  }

  getNextAndPrevious(
    eventId: string,
    momentId: string
  ): Observable<{
    next: { eventId: string; momentId: string };
    previous: { eventId: string; momentId: string };
  }> {
    return this.getTimeline().pipe(
      map((timeline) => {
        let prev = null;
        let prevMoment = null;
        let current = null;
        let next = null;
        let nextMoment = null;
        for (const moment of timeline) {
          for (const ev of moment.events) {
            if (ev.id === eventId && moment.id === momentId) {
              current = ev;
            } else {
              if (current !== null) {
                next = ev.id;
                nextMoment = moment.id;
                return {
                  next: { eventId: next, momentId: nextMoment },
                  previous: { eventId: prev, momentId: prevMoment },
                };
              } else {
                prev = ev.id;
                prevMoment = moment.id;
              }
            }
          }
        }
        if (current) {
          return {
            next: { eventId: next, momentId: nextMoment },
            previous: { eventId: prev, momentId: prevMoment },
          };
        } else {
          return null;
        }
      })
    );
  }
}
