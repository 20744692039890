import { InjectionToken } from '@angular/core';
import { NestedPartial } from './nestedpartial';

export const FILTER_SERVICE_TOKEN = new InjectionToken('FilterService');

export interface FilterServiceInterface {
  filter<T>(list: T[], filters: NestedPartial<T>, ignoreCase?: boolean): T[];

  matchFilters<T>(
    item: T,
    filters: NestedPartial<T>,
    ignoreCase?: boolean
  ): boolean;
}
