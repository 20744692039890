import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsComponent } from './pages/events/events.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { MenuComponent } from './pages/menu/menu.component';
import { SearchComponent } from './pages/search/search.component';
import { MaterialsComponent } from './pages/materials/materials.component';
import { EventDetailComponent } from './pages/events/event-detail/event-detail.component';
import { MaterialDetailComponent } from './pages/materials/material-detail/material-detail.component';

const routes: Routes = [
  {
    path: 'events',
    component: EventsComponent
  },
  {
    path: 'events/:moment/:id',
    component: EventDetailComponent
  },
  {
    path: 'contacts',
    component: ContactsComponent
  },
  {
    path: 'menu',
    component: MenuComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'materials',
    component: MaterialsComponent
  },
  {
    path: 'materials/:id',
    component: MaterialDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
